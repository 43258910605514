export const columns = [
  {
    title: "序号",
    dataIndex: "index",
    fixed: "left",
    width: 60,
    customRender: (value, item, index) => index + 1,
  },
  {
    title: "产品编号",
    dataIndex: "material_number",
    width: 180,
    ellipsis: true,
  },
  {
    title: "产品名称",
    dataIndex: "material_name",
    width: 240,
  },
  {
    title: "移库数量",
    dataIndex: "transfer_quantity",
    width: 100,
    ellipsis: true,
  },
  {
    title: "状态",
    dataIndex: "status",
    width: 100,
    ellipsis: true,
    customRender: (value, item, index) => {
      return item.is_void ? "已作废" : "完成";
    },
  },
  {
    title: "仓库",
    dataIndex: "warehouse_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: "源库位",
    dataIndex: "location_out_number",
    width: 120,
    ellipsis: true,
  },
  {
    title: "目标库位",
    dataIndex: "location_in_number",
    width: 120,
    ellipsis: true,
  },
  {
    title: "批次编号",
    dataIndex: "batch_number",
    width: 180,
    ellipsis: true,
  },
  {
    title: "创建时间",
    dataIndex: "create_time",
    width: 180,
    ellipsis: true,
  },
  {
    title: "创建人",
    dataIndex: "creator_name",
    width: 120,
    ellipsis: true,
  },
  {
    title: "操作",
    dataIndex: "action",
    fixed: "right",
    width: 100,
    scopedSlots: { customRender: "action" },
  },
];
